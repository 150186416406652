import { Injectable, isDevMode } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Auth } from '../../models/auth';
import { Store } from '@ngrx/store';
import { AppState } from '../store/store.module';
import { getToken } from '../store/auth/selectors';
import {Token} from '../../models/token';
import {filter} from 'rxjs/operators';

@Injectable()
export class ApiClient {

    data: Auth;
    token = null;
    apiEndpoint = '';

    constructor(
        private store: Store<AppState>,
        private http: HttpClient
    ) {
        this.store.select(getToken)
          .pipe(
            filter(token => !!token)
          )
          .subscribe(token => this.token = token.access_token);
        this.apiEndpoint = `${isDevMode() ? '' : window['apiEndpoint']}${environment.API_ENDPOINT}`;
    }

    get(url = null, parameters = {}) {

        let params = new HttpParams();
        Object.keys(parameters).map(key => {
            params = params.append(key, parameters[key]);
        });

        return this.http.get(`${this.apiEndpoint}/${url}`, {
            // headers: { 'Authorization': `Bearer ${this.token}`},
            params
        });
    }

    post(url, postData) {

        return this.http.post(`${this.apiEndpoint}/${url}`, postData);

    }

    put(url, postData) {
        return this.http.put(`${this.apiEndpoint}/${url}`, postData, {
            // headers: { 'Authorization': `Bearer ${this.token}`},
        });
    }

    delete(url) {

        return this.http.delete(`${this.apiEndpoint}/${url}`);

    }

    postGuest(url, data) {
        return this.http.post(`${this.apiEndpoint}/${url}`, data);
    }

    getEndpoint(url, parameters = {}, withToken = true) {
        const stringParameters = Object.keys(parameters).map(key => `${key}=${parameters[key]}`).join('&');
        return `${this.apiEndpoint}/${url}?access_token=${withToken ? this.token : ''}&${stringParameters}`;
    }

}
