import { ApiClient } from '../../core/services/api.service';
import * as i0 from "@angular/core";
import * as i1 from "../../core/services/api.service";
export class FeatureService {
    constructor(http) {
        this.http = http;
        this.apiSegment = 'prodotti';
    }
    /**
     * Elenco prodotti
     *
     * @param onlyADomiclio boolean Solo prodotti a domicilio
     * @param categoryId null | int Id categoria filtro
     */
    all(soloVendita = false, categoryId = null) {
        return this.http.get(`${this.apiSegment}?${soloVendita ? 'onlyVendita=1&' : ''}${categoryId ? 'category_id=' + categoryId + '&' : ''}`);
    }
    create(data) {
        return this.http.post(`${this.apiSegment}`, data);
    }
    item(id) {
        return this.http.get(`${this.apiSegment}/${id}`);
    }
    update(id, data) {
        if (data instanceof FormData) {
            data.append('_method', 'PUT');
        }
        else {
            data = Object.assign({}, data, { '_method': 'PUT' });
        }
        return this.http.post(`${this.apiSegment}/${id}`, data);
    }
    delete(id) {
        return this.http.delete(`${this.apiSegment}/${id}`);
    }
    categories() {
        return this.http.get(`categorie-prodotti`);
    }
}
FeatureService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FeatureService_Factory() { return new FeatureService(i0.ɵɵinject(i1.ApiClient)); }, token: FeatureService, providedIn: "root" });
