
    <ng-container  *ngIf="!(hasCustomProductsPermission$ | async) else customProducts">
      <table width="100%" *ngIf="canEdit">
        <tr>
          <td class="pt-0" style="border-top: 0">
            <ng-select
              [items]="productsList"
              [(ngModel)]="selectedProduct"
              bindLabel="name"
              class="select-product"
              [multiple]="false"
              [closeOnSelect]="true"
              [searchFn]="customSearchFn"
            >
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <div class="CategoryBox">
                  <div class="CategoryBox_Color" [style.background]="item.category?.color"></div>
                  <div class="CategoryBox_Text">{{item.name}}</div>
                </div>
              </ng-template>
            </ng-select>
          </td>
          <td width="60" class="text-right pt-0" style="border-top: 0">
            <button type="button" (click)="handleAddProdotto()" class="btn btn-default"><span class="flaticon2-add"></span></button>
          </td>
        </tr>
      </table>
    </ng-container>
    <ng-template #customProducts>
        <app-linee-prodotto-selector [filtro]="filtro" [vendita]="vendita" (addProducts)="handleAddProducts($event)"></app-linee-prodotto-selector>
    </ng-template>

    <table width="100%" cdkDropListGroup class="table table-vertical-center">
      <tbody cdkDropList [cdkDropListData]="selectedProducts" (cdkDropListDropped)="drop($event)">
        <tr *ngFor="let product of selectedProducts; let i = index" cdkDrag style="cursor: move">
            <td class="pt-2">
              {{product.name}}
              <input *ngIf="note" type="text" class="form-control" placeholder="Note..." (change)="handleUpdate($event, i)" [value]="product.note ? product.note : ''">
            </td>
            <td class="pt-2 text-right" *ngIf="canEdit">
          <button type="button" (click)="handleRemoveProduct(i)"  class="btn btn-danger btn-sm"><span class="flaticon2-trash"></span></button>
        </td>
        </tr>
      </tbody>
    </table>
  