import { Injectable} from '@angular/core';
import { ApiClient } from '../../core/services/api.service';

@Injectable({
    providedIn: 'root',
})

export class FeatureService {

    private apiSegment = 'prodotti';

    constructor(
        private http: ApiClient
    ) {
    }

  /**
   * Elenco prodotti
   *
   * @param onlyADomiclio boolean Solo prodotti a domicilio
   * @param categoryId null | int Id categoria filtro
   */

  all(soloVendita = false, categoryId = null) {

        return this.http.get(`${this.apiSegment}?${soloVendita ? 'onlyVendita=1&' : ''}${categoryId ? 'category_id=' + categoryId + '&' : ''}`);

    }

    create(data) {

        return this.http.post(`${this.apiSegment}`, data);

    }

    item(id) {

        return this.http.get(`${this.apiSegment}/${id}`);

    }

    update(id, data) {

        if(data instanceof FormData) {
            data.append('_method', 'PUT');
        } else {
            data = Object.assign({}, data, { '_method': 'PUT' });
        }

        return this.http.post(`${this.apiSegment}/${id}`, data);

    }

    delete(id) {

        return this.http.delete(`${this.apiSegment}/${id}`);

    }

    categories() {

        return this.http.get(`categorie-prodotti`)

    }

}
